import * as React from 'react';
import { Avatar, Card, CardHeader, CardMedia, CardContent, Stack, Typography } from '@mui/material';
import ConstructionIcon from '@mui/icons-material/Construction';

interface UnderConstructionProps {
    msg: string
}
 
const UnderConstruction: React.FC<UnderConstructionProps> = (props) => {
    return ( 
        <Stack spacing={2} justifyContent="center" alignItems="center" sx={{minHeight:'100%'}}>
            <Card sx={{ maxWidth: 450 }}>
                <CardHeader avatar={
                    <Avatar>
                        <ConstructionIcon />
                    </Avatar>
                } title={props.msg} subheader="We're working on it!" />
                <CardMedia component="img" image="https://coreprodeuscclstg.blob.core.windows.net/public-web-assets/logos/ccl-logo-with-text-horizontal.png" alt="Under Construction" />
                <CardContent>
                    <Typography variant="body2" color="text.secondary">
                        Come back soon to check our progress.
                    </Typography>
                </CardContent>
            </Card>
        </Stack>
     );
}
 
export default UnderConstruction;


